import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import Sound from 'react-sound';
import UIfx from 'uifx';
import { useImagesContext } from '../../contexts/ImagesContext';

import {
  getStudent,
  editPoint as editPointService,
  bonusPointSettle as bonusPointSettleService,
  redeemPoint as redeemPointService,
  unlock as unlockService,
  getPowers,
  getRank,
} from '../../services/student';
import * as configActions from '../../actions/config';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from '../../utils/utility';
import { NumberImageYellow, TextImageYellow } from '../utils/NumberImage';
import { setSelectStudent } from '../../actions/student';
import { bookOpenBackward, bookOpenDisappear } from '../../actions/book';
import { BOOK_BACKWARD_DURATION, LEVEL_DISPLAY } from '../../constant';

import redeemSound from '../../sounds/03.mp3';
import bonusSound from '../../sounds/04.mp3';

const soundRedeem = new UIfx(redeemSound);
const soundBonus = new UIfx(bonusSound);

const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  backButton: {
    position: 'absolute',
    left: 260,
    bottom: 250,
  },
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  book: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
    zIndex: 0,
    animation: 'fadeInAnimation .2s ease-in-out',
  },
  mainBlock: {
    position: 'relative',
    width: 1150,
    height: 650,
    // border: '1px solid #000',
    top: 180,
    left: 406,
    display: 'flex',
    zIndex: 1,
  },
  leftBlock: {
    width: '50%',
    height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // border: '1px solid #000',
    // background: 'red',
  },
  leftBlock1: {
    display: 'flex',
  },
  rightBlock: {
    position: 'relative',
    width: '50%',
    height: '100%',
    // border: '1px solid #000',
    // background: 'green',
  },
  avatarBlock: {
    position: 'relative',
    width: 268,
    height: 268,
    marginLeft: 30,
  },
  studentAvatar: {
    position: 'absolute',
    width: 250,
    height: 250,
    borderRadius: '50%',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  avatarBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 268,
    height: 268,
    margin: 'auto',
  },
  studentCode: {
    position: 'absolute',
    bottom: -40,
    left: -30,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 46,
    textStroke: '1px white',
    paintOrder: 'stroke fill',
    padding: '0px 20px 20px 40px',
    zIndex: 2,
  },
  studentName: {
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 48,
    textStroke: '1px white',
    paintOrder: 'stroke fill',
    height: 70,
    textAlign: 'center',
    padding: '0px 40px 0px 40px',
  },
  infoBlock: {
    position: 'relative',
    width: 280,
    height: 280,
    marginLeft: 0,
    marginTop: 0,
  },
  studentInfoBackground: {
    position: 'absolute',
    top: 65,
    left: 40,
    zIndex: 0,
  },
  textLevel: {
    position: 'relative',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 60,
    textStroke: '3px white',
    paintOrder: 'stroke fill',
    paddingTop: 4,
    textAlign: 'center',
    zIndex: 1,
  },
  studentLevel: {
    position: 'relative',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 120,
    textStroke: '4px white',
    paintOrder: 'stroke fill',
    lineHeight: 0.4,
    textAlign: 'center',
    zIndex: 1,
  },
  studentPoint: {
    position: 'relative',
    marginTop: 20,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: 0.8,
    textStroke: '2px white',
    paintOrder: 'stroke fill',
    textAlign: 'center',
    width: 160,
    height: 40,
    zIndex: 1,
    margin: 'auto',
  },
  leftBlock2: {
    display: 'flex',
    marginTop: 50,
    marginLeft: 12,
  },
  studentItemBackground: {
    position: 'absolute',
    zIndex: 0,
  },
  studentItemBlock: {
    position: 'relative',
    width: 260,
    height: 260,
  },
  studentItemDetail: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    zIndex: 1,
    width: 240,
    height: 220,
    marginTop: 65,
    marginLeft: 20,
    // border: '1px solid #000',
  },
  studentItem: {
    width: 103,
    height: 103,
  },
  studentRankBlock: {
    position: 'relative',
    marginLeft: 25,
    marginTop: 30,
    width: 260,
    height: 260,
    // border: '1px solid #000',
    textAlign: 'center',
  },
  rankHead: {
    position: 'relative',
    zIndex: 1,
  },
  rank: {
    marginTop: -70,
  },
  rankText: {
    marginTop: -120,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 140,
    textStroke: '4px white',
    paintOrder: 'stroke fill',
    lineHeight: 0,
  },
  rightBlock1: {
    // position: 'relative',
    // display: 'block',
    // textAlign: 'center',
  },
  board: {
    // position: 'relative',
    marginTop: -10,
    margin: 'auto',
    width: 460,
    height: 421,
  },
  pointExtra: {
    position: 'relative',
    top: 100,
    paddingLeft: 40,
    paddingTop: 15,
    margin: 'auto',
    width: 401,
    height: 99,
  },
  pointEditBlock: {
    position: 'relative',
    top: 110,
    margin: 'auto',
    width: 278,
    height: 75,
  },
  pointEdit: {
    width: '60%',
    height: '100%',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    textStroke: '4px white',
    paintOrder: 'stroke fill',
    fontSize: 50,
    paddingLeft: 80,
    paddingTop: 0,
    marginTop: -5,
    paddingRight: 50,
    background: 'transparent',
    border: 'None',
    // textAlign: 'center',
    '&:focus': {
      borderColor: '#66afe9',
      boxShadow:
        'inset 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(102, 175, 233, 0.6)',
      outline: 0,
    },
  },
  pointRedeemBlock: {
    position: 'relative',
    top: 120,
    margin: 'auto',
    width: 275,
    height: 89,
  },
  pointRedeem: {
    width: '60%',
    height: '100%',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    textStroke: '4px white',
    paintOrder: 'stroke fill',
    fontSize: 50,
    paddingLeft: 80,
    paddingTop: 4,
    paddingRight: 50,
    border: 'None',
    background: 'transparent',
    // textAlign: 'center',
    '&:focus': {
      borderColor: '#66afe9',
      boxShadow:
        'inset 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(102, 175, 233, 0.6)',
      outline: 0,
    },
  },
  hiddenButton: {
    position: 'absolute',
    left: 240,
    top: -5,
    width: 90,
    height: 90,
    borderRadius: '50%',
    // border: '1px solid #000',
  },
  imgOk: {
    position: 'absolute',
    right: 25,
    top: 340,
    zIndex: 1,
  },
  dayPoint: {
    display: 'block',
    margin: 'auto',
    marginTop: 10,
  },
  dayPointText: {
    // textAlign: 'center',
    margin: 'auto',
    marginTop: -120,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 140,
    width: 200,
    textStroke: '4px white',
    paintOrder: 'stroke fill',
    lineHeight: 0,
  },
  powerItemType: {
    // objectFit: 'contain',
    height: 100,
    // width: '100%',
  },
}));

const Dashboard = ({
  history,
  match,
  config,
  setSelectStudent,
  bookOpenBackward,
  bookOpenDisappear,
  studentState,
}) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles());
  const studentId = studentState.id;
  const [student, setStudent] = useState(null);
  const [isPass, setIsPass] = useState(false);
  const [powers, setPowers] = useState(null);
  const [rank, setRank] = useState(null);
  // const { url } = useRouteMatch();

  const goBack = (e) => {
    console.debug('goBack');
    e.stopPropagation();
    bookOpenBackward();
    setTimeout(() => {
      bookOpenDisappear();
      history.push('/home');
    }, BOOK_BACKWARD_DURATION);
  };

  const [editPoint, setEditPoint] = useState({
    point: '',
    password: '',
  });

  const [redeem, setRedeem] = useState(null);

  const onChangeEditPoint = (e) => {
    setEditPoint({ ...editPoint, [e.target.name]: e.target.value });
  };

  const onSummitEditPoint = () => {
    if (editPoint.point == '') return;
    console.debug(`isPass: ${isPass}`);
    if (!isPass) return;
    console.debug('editPoint', editPoint);
    setIsPass(false);
    editPointService(studentId, editPoint.point, 'password')
      .then((res) => {
        console.debug(res);
        setEditPoint({
          point: '',
          password: '',
        });
        const newStudent = { ...student, ...res.data };
        setStudent(newStudent);
        setSelectStudent(newStudent);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error('Error', error.message);
        }
      });
  };

  const gotoHall = () => {
    history.push(`/hall`);
  };

  const bonusPointSettle = () => {
    console.debug('bonusPointSettle', student);
    if (student == null || student.bonus == null || student.bonus == 0) return;
    bonusPointSettleService(studentId)
      .then((res) => {
        console.debug(res);
        const newStudent = { ...student, ...res.data };
        setStudent(newStudent);
        setSelectStudent(newStudent);
        if (!config.isMutedSound) soundBonus.play();
      })
      .catch((err) => console.error('bonusPointSettle', err));
  };

  const redeemPointFunc = () => {
    console.debug('redeemPoint', student, redeem);
    if (redeem == null || redeem == '' || redeem == 0) return;
    if (isPass) {
      setIsPass(false);
      redeemPointService(studentId, redeem)
        .then((res) => {
          console.debug(res);
          !config.isMutedSound && soundRedeem.play();
          setRedeem(null);
          const newStudent = { ...student, ...res.data };
          setStudent(newStudent);
          setSelectStudent(newStudent);
        })
        .catch((err) => {
          console.error('redeemPoint', err);
        });
    }
  };

  const handleDaysPoint = () => {
    bonusPointSettle();
  };

  const handleOk = () => {
    onSummitEditPoint();
    redeemPointFunc();
  };

  useEffect(() => {
    console.debug('useEffect', studentId);
    async function _getData() {
      try {
        let res = await getStudent(studentId);
        setStudent(res.data);
        res = await getPowers(studentId);
        console.debug('powers', res.data);
        setPowers(res.data);
        res = await getRank(studentId);
        setRank(res.data.rank);
      } catch (err) {
        console.error(err);
      }
    }
    _getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={c('book')}
      onClick={gotoHall}
      style={{
        backgroundImage: `url(${images['dashboard-content.png']})`,
      }}
    >
      <img
        className={c('backButton point')}
        src={images['back.png']}
        alt=''
        onClick={goBack}
        onContextMenu={(e) => e.preventDefault()}
      />
      <div
        className={c('mainBlock')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={c('leftBlock')}>
          <div className={c('leftBlock1')}>
            <div className={c('avatarBlock')}>
              <img
                className={c('avatarBorder')}
                src={images['avatar-border.png']}
                alt=''
              />
              <img
                className={c('studentAvatar')}
                src={student ? student.image : images['dashboard-picture.png']}
                alt=''
              />
              <div
                className={c('studentCode')}
                style={{
                  backgroundImage: `url(${images['student-code.png']})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                }}
              >
                {student && student.code}
              </div>
            </div>
            <div className={c('infoBlock')}>
              <div
                className={c('studentName')}
                style={{
                  backgroundImage: `url(${images['student-name.png']})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                }}
              >
                {student && student.name}
              </div>
              <div>
                <img
                  className={c('studentInfoBackground')}
                  src={images['student-level.png']}
                  alt=''
                />
                <div className={c('textLevel')}>LEVEL</div>
                <div className={c('studentLevel')}>
                  {student?.level == 16 ? (
                    <img src={images['pre.png']} alt='' />
                  ) : (
                    <TextImageYellow
                      height={81}
                      text={LEVEL_DISPLAY[student?.level]}
                    />
                  )}
                </div>
                <div
                  className={c('studentPoint')}
                  style={{
                    backgroundImage: `url(${images['student-point.png']})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                  }}
                >
                  {student && student.pointExtra}
                </div>
              </div>
            </div>
          </div>
          <div className={c('leftBlock2')}>
            <div className={c('studentItemBlock')}>
              <img
                className={c('studentItemBackground')}
                src={images['student-item-block.png']}
                alt=''
              />
              <div className={c('studentItemDetail')}>
                {powers &&
                  powers.studentPower.items.map((item, index) => (
                    <div key={index} className={c('studentItem')}>
                      <img
                        className={c('powerItemType')}
                        src={images[`${item.item_key}.png`]}
                        alt={item.item_key}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={c('studentRankBlock')}>
              <img
                className={c('rankHead')}
                src={images['rank-head.png']}
                alt=''
              />
              <img className={c('rank')} src={images['rank.png']} alt='' />
              <div className={c('rankText')}>
                {rank ? (
                  <NumberImageYellow height={70} number={rank} />
                ) : (
                  <img src={images['no-rank.png']} alt='' />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={c('rightBlock')}>
          <div className={c('rightBlock1')}>
            <div
              className={c('board')}
              style={{
                backgroundImage: `url(${images['board.png']})`,
              }}
            >
              <div
                className={c('pointExtra')}
                style={{
                  background: `url(${images['point.png']}) no-repeat left top`,
                }}
              >
                <NumberImageYellow height={70} number={student?.point || 0} />
              </div>
              <div
                className={c('pointEditBlock')}
                style={{
                  background: `url(${images['bonus.png']}) no-repeat left top`,
                }}
              >
                <input
                  type='text'
                  className={c('pointEdit')}
                  name='bonus'
                  onChange={(e) =>
                    setEditPoint({
                      ...editPoint,
                      point: parseInt(e.target.value),
                    })
                  }
                  value={editPoint.point || ''}
                  autoComplete='off'
                  spellCheck='false'
                  placeholder=''
                  pattern='\d*'
                  maxLength='4'
                  // readOnly
                />
              </div>
              <div
                className={c('pointRedeemBlock')}
                style={{
                  background: `url(${images['gift.png']}) no-repeat left top`,
                }}
              >
                <input
                  type='text'
                  className={c('pointRedeem')}
                  name='pointRedeem'
                  value={`${redeem || ''}`}
                  onChange={(e) => setRedeem(parseInt(e.target.value))}
                  autoComplete='off'
                  spellCheck='false'
                  placeholder=''
                  pattern='\d*'
                  maxLength='4'
                />
              </div>
            </div>
            <div
              className={c('hiddenButton')}
              onClick={() => {
                setIsPass(true);
              }}
            ></div>
            <img
              onClick={handleOk}
              className={c('imgOk point')}
              src={images['ok.png']}
              alt=''
            />
            <img
              className={c('dayPoint')}
              src={images['day-point.png']}
              alt=''
            />
            <div onClick={handleDaysPoint} className={c('dayPointText')}>
              <NumberImageYellow height={70} number={student?.bonus} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ config, student }) => ({
  config,
  studentState: student,
});

const mapDispatchToProps = {
  setMuteSound: configActions.setMuteSound,
  setSelectStudent,
  bookOpenBackward,
  bookOpenDisappear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
