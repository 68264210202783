import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import UIfx from 'uifx';
import { playFinish as playFinishService } from '../../../services/game-play';
import soundLost from '../../../sounds/24.mp3';
import soundWin from '../../../sounds/25.mp3';
// import soundSelectFile from '../../../sounds/15.mav';

const useStyles = makeStyles((theme) => ({
  warpper: {
    position: 'absolute',

    zIndex: '8000',
  },
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1920px',
    height: '1080px',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  scoreContent: {
    position: 'relative',
    height: '610px',
    width: '100%',
    '& [playerimg]': {
      position: 'absolute',
      left: 'calc(50% - 108px)',
    },
    '& [scorebox],[capturebox]': {
      position: 'absolute',
      width: '670px',
      height: '220px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img,div': {
        height: 'fit-content',
      },
      '& > :not(:last-child)': {
        marginRight: '30px',
      },
      '& [spacebox]': {
        width: '229px',
        height: '125px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& [pointdigit1],[pointdigit2]': {
          height: '100px',
          filter: 'drop-shadow(-2px 4px 3px black)',
        },
        '& [minus]': {
          height: '30px',
          filter: 'drop-shadow(-2px 4px 3px black)',
        },
        '& [dot]': {
          height: '90px',
          alignSelf: 'flex-end',
          marginLeft: '-30px',
          filter: 'drop-shadow(-2px 4px 3px black)',
        },
        '& [additnum]': {
          height: '100px',
          marginLeft: '-30px',
          filter: 'drop-shadow(-2px 4px 3px black)',
        },
        '& img:not(:last-child)': {
          marginRight: '5px',
        },
        '& [spaceimg]': {
          position: 'absolute',
          width: '250px',
        },
      },
    },
    '& [scorebox]': {
      left: 'calc(50% - 310px)',
      top: '230px',
    },
    '& [capturebox]': {
      left: 'calc(50% - 320px)',
      top: '385px',
    },
  },
  imageBackBtn: {
    marginLeft: '60px',
    width: '84px',
    height: '84px',
    '&:hover': {
      //cursor: 'pointer',
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },

  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
}));

// const soundSelect = new UIfx(soundSelectFile);

const Score = (props) => {
  const classes = useStyles();
  const { student, score, capture, status, playerInfo } = props;
  const { images } = useImagesContext();
  const [isShowScoreContent, setIsShowScoreContent] = useState(false);
  //const [point, setPoint] = useState(0);

  React.useEffect(() => {
    playFinishService(student.id, +capture, status === 'win');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const containerRefHandler = () => {
    setTimeout(() => {
      setIsShowScoreContent(true);
    }, 1100);
  };

  const getDigit = (number, digitPos) => {
    let ndigitPos = digitPos;
    if (number < 0) {
      ++ndigitPos;
    }
    if (ndigitPos < 1 || ndigitPos > String(number).length) {
      return 0;
    }
    return +String(number)[ndigitPos - 1];
  };

  const getDigitLen = (number) => {
    return String(Math.abs(number)).length;
  };

  const spaceBox = (point, isAddHalfPonit = false) => {
    return (
      <div spacebox=''>
        <img spaceimg='' src={images['score_space.png']} alt=''/>
        {point < 0 ? <img minus='' src={images[`minus.png`]} alt='' /> : null}
        <img
          pointdigit1=''
          src={images[`score_${getDigit(point, 1)}.png`]}
          alt=''
        />
        {isAddHalfPonit ? (
          <>
            <img dot='' src={images[`dot.png`]} alt='' />{' '}
            <img additnum='' src={images[`score_${5}.png`]} alt='' />
          </>
        ) : null}

        {getDigitLen(point) > 1 ? (
          <img
            pointdigit2=''
            src={images[`score_${getDigit(point, 2)}.png`]}
            alt=''
          />
        ) : null}
      </div>
    );
  };

  const adjustAudioScore = () => {
    document.getElementById('audio_gameplay_score').volume = 0.6;
  };

  return (
    <React.Fragment>
      {!props.config.isMutedSound ? (
        <audio
          ref={adjustAudioScore}
          id='audio_gameplay_score'
          key={'audio_gameplay_score'}
          hidden
          src={status === 'win' ? soundWin : soundLost}
          autoPlay
          controls
        ></audio>
      ) : null}

      <div className={classes.warpper}>
        <div
          style={{
            backgroundImage: `url(${
              images[`score_a${status === 'win' ? 'WIN' : 'LOSE'}.png`]
            })`,
          }}
          className={classes.root}
        >
          <div className={classes.container} ref={containerRefHandler}>
            {isShowScoreContent ? (
              <div className={classes.scoreContent}>
                <img playerimg='' src={images[playerInfo.selectedBigP1]} alt='' />
                {score !== null ? (
                  <div scorebox=''>
                    <img
                      winimg=''
                      src={
                        images[`score_${status === 'win' ? 'win' : 'lose'}.png`]
                      }
                      alt=''
                    />
                    {spaceBox(score, status === 'equal')}
                    <img
                      pointimg=''
                      src={
                        images[
                          `score_${status === 'win' ? 'pointw' : 'pointl'}.png`
                        ]
                      }
                      alt=''
                    />
                  </div>
                ) : null}

                <div capturebox=''>
                  <img captureimg='' src={images['score_capture.png']} alt='' />
                  {spaceBox(capture)}
                </div>
              </div>
            ) : null}
          </div>
          <footer id='footer' className={classes.footer}>
            <Grid container justify='space-between'>
              <Grid item xs container>
                <img
                  className={classes.imageBackBtn}
                  src={images['score_back_btn.png']}
                  id='back'
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={() => props.backHandler()}
                  alt=''
                />
              </Grid>
            </Grid>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config,
});

export default connect(mapStateToProps, null)(Score);
